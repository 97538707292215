import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static get targets() {
    return ['range', 'rangeWarningModal'];
  }

  connect() {
    this.bsModal = Modal.getOrCreateInstance(this.rangeWarningModalTarget);
  }

  disconnect() {
    this.bsModal.dispose();
  }

  submitForm() {
    if (this.getInvalidInputs().length === 0) {
      this.element.requestSubmit();
    } else {
      this.bsModal.show();
    }
  }

  getInvalidInputs() {
    return this.rangeTargets.filter((el) => parseInt(el.value, 10) === (parseInt(el.max, 10) / 2));
  }
}
