import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static get targets() {
    return ['form', 'radioButton', 'timeWarningModal', 'remaining', 'label'];
  }

  static get values() {
    return { timeLimit: Number };
  }

  connect() {
    this.bsModal = Modal.getOrCreateInstance(this.timeWarningModalTarget);
  }

  disconnect() {
    this.bsModal.dispose();
  }

  formTargetConnected() {
    this.remainingTime = this.timeLimitValue;
    this.updateTimer();
    this.startTimer();
  }

  formTargetDisconnected() {
    this.stopTimer();
  }

  startTimer() {
    this.timerInterval = setInterval(() => {
      this.remainingTime -= 1;
      this.updateTimer();

      if (this.remainingTime <= 0) {
        this.stopTimer();

        if (this.radioButtonTargets[0].checked || this.radioButtonTargets[1].checked) {
          this.submitDecision();
        } else {
          this.bsModal.show();
        }
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timerInterval);
  }

  updateTimer() {
    const rawTimeFraction = this.remainingTime / this.timeLimitValue;
    const remainingDash = ((rawTimeFraction - (1 / this.timeLimitValue) * (1 - rawTimeFraction)) * 283)
      .toFixed(0);

    this.labelTarget.innerText = this.remainingTime;
    this.remainingTarget.setAttribute('stroke-dasharray', `${remainingDash} 283`);
  }

  submitDecision() {
    clearTimeout(this.submitTimeoutID);
    this.formTarget.requestSubmit();
  }

  submitDecisionDelayed() {
    clearTimeout(this.submitTimeoutID);

    this.submitTimeoutID = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 750);
  }
}
