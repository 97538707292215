import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['checkbox', 'counterSuccess', 'counter', 'submit',
      'minWarningCounter', 'minCounterWarning', 'maxWarningCounter', 'maxCounterWarning'];
  }

  static get values() {
    return {
      minSelection: Number,
      maxSelection: Number,
    };
  }

  connect() {
    this.updateCounterTarget();

    this.checkboxTargetConnected = () => {
      this.updateCounterTarget();
      this.getControllerByIdentifier('core-value-modal').bsModal.hide();
    };
  }

  updateCounterTarget() {
    const selectedValues = this.checkboxTargets.filter((el) => el.checked);

    if (selectedValues.length > this.maxSelectionValue) {
      this.maxWarningCounterTarget.innerText = selectedValues.length - this.maxSelectionValue;
      this.showMaxCounterWarning();
      this.disableNextButton();
    } else if (selectedValues.length < this.minSelectionValue) {
      this.minWarningCounterTarget.innerText = this.minSelectionValue - selectedValues.length;
      this.showMinCounterWarning();
      this.disableNextButton();
    } else {
      this.counterTarget.innerText = selectedValues.length;
      this.hideCounterWarnings();
      this.enableNextButton();
    }
  }

  disableNextButton() {
    this.counterTarget.classList.replace('text-success', 'text-danger');
    this.submitTarget.classList.add('disabled');
  }

  enableNextButton() {
    this.counterTarget.classList.replace('text-danger', 'text-success');
    this.submitTarget.classList.remove('disabled');
  }

  showMaxCounterWarning() {
    this.counterSuccessTarget.style.display = 'none';
    this.maxCounterWarningTarget.style.display = 'block';
    this.minCounterWarningTarget.style.display = 'none';
  }

  showMinCounterWarning() {
    this.counterSuccessTarget.style.display = 'none';
    this.maxCounterWarningTarget.style.display = 'none';
    this.minCounterWarningTarget.style.display = 'block';
  }

  hideCounterWarnings() {
    this.counterSuccessTarget.style.display = 'block';
    this.maxCounterWarningTarget.style.display = 'none';
    this.minCounterWarningTarget.style.display = 'none';
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find((controller) => controller.context.identifier === identifier);
  }
}
