import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import * as bootstrap from 'bootstrap';
import '../src/javascripts/controllers';
import '../src/javascripts/channels';

require.context('../images', true);

Rails.start();
ActiveStorage.start();

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.toast')
    .forEach((toastEl) => bootstrap.Toast.getOrCreateInstance(toastEl).show());
});

document.addEventListener('turbo:before-cache', () => {
  document.querySelectorAll('.toast').forEach((toastEl) => {
    bootstrap.Toast.getInstance(toastEl).dispose();
    toastEl.remove();
  });
});
