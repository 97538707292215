import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static get targets() {
    return ['titleInput'];
  }

  connect() {
    this.bsModal = Modal.getOrCreateInstance(this.element);
  }

  disconnect() {
    this.bsModal.dispose();
  }

  focusTitleInput() {
    this.titleInputTarget.focus();
  }
}
