import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['rank', 'loadRanksBtn'];
  }

  connect() {
    if (this.rankTargets.length > 5) {
      this.hideRanks();
    } else {
      this.loadRanksBtnTarget.classList.add('disabled');
    }
  }

  showMoreRanks() {
    const currentScrollPos = window.scrollY;
    const cardHeight = this.rankTarget.offsetHeight + parseInt(getComputedStyle(this.rankTarget).marginBottom, 10);

    this.rankTargets.splice(5).forEach((el, i) => {
      setTimeout(() => {
        el.style.display = 'block';
        el.style.animation = 'fade-in 1.25s';
        window.scrollTo(0, (i + 1) * cardHeight + currentScrollPos);
      }, i * 150);
    });

    this.loadRanksBtnTarget.classList.add('disabled');
    this.loadRanksBtnTarget.blur();
  }

  hideRanks() {
    this.rankTargets.splice(5).forEach((el) => {
      el.style.display = 'none';
    });
  }
}
