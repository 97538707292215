import { Controller } from 'stimulus';
import { Tooltip } from 'bootstrap';

export default class extends Controller {
  connect() {
    this.bsTooltip = Tooltip.getOrCreateInstance(this.element);
  }

  disconnect() {
    this.bsTooltip.dispose();
  }
}
