import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['range', 'output'];
  }

  connect() {
    this.setOutput();
  }

  setOutput() {
    const min = this.rangeTarget.min ? this.rangeTarget.min : 0;
    const max = this.rangeTarget.max ? this.rangeTarget.max : 100;
    const newPos = Number(((this.rangeTarget.value - min) * 100) / (max - min));

    this.outputTarget.innerText = `${this.rangeTarget.value}%`;
    this.outputTarget.style.left = `calc(${newPos}% + (${8 - newPos * 0.1575}px))`;
  }
}
